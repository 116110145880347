import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Abdominal bloating";

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "stomach bloating", "IBS", "Diet related bloating", "Abdominal distension"]}
			type="article"
			description="Abdominal bloating - feeling full and tight in the abdomen 'stomach' area. Causes, diet, IBS, tips"
		/>
		<H1>{title}</H1>
		<h2 className="mt-4">What is it?</h2>
		<Par>
			Abdominal bloating is felt by patients as a feeling of fullness, tightness or distension in the abdomen. Bloating
			is different to abdominal swelling where the abdomen is actually increased in size but both of these features may
			be present.
		</Par>
		<h2 className="mt-4">Common causes of abdominal bloating</h2>
		<Par>
			Abdominal bloating may be a feature of a number of disorders the majority of which are not serious and result from
			changes in gastrointestinal function. Common causes are listed below:
		</Par>
		<ul>
			<li className="mt-3">
				<b>Diet</b>
				<br />
				Excessive fat or fibre consumption in the diet can cause bloating as the body takes a long time to break down
				and expel these foods from the body. Other foods can cause bloating due to formation of extra gas these include
				cabbage, cauliflower, baked beans and salads.
			</li>
			<li className="mt-3">
				<b>Constipation</b>
				<br />
				This disorder is characterised by a reduction in the frequency of bowel motions and maybe associated with
				bloating.{" "}
			</li>
			<li className="mt-3">
				<b>Menstruation</b> <br />
				Bloating is common in women at the time of menstruation as more or less part of a premenstrual stress disorder.
			</li>
			<li className="mt-3">
				<b>Irritable bowel syndrome (IBS)</b>
				<br />
				This is a common disorder where patients have abdominal pain classically associated with alternating episodes of
				constipation and diarrhoea. The pain is frequently eased by passing a bowel motion or passing wind. Abdominal
				bloating is one of the key features of this condition.
			</li>
			<li className="mt-3">
				<b>Air swallowing</b>
				<br />
				If you swallow large amounts of air your bowel has trouble absorbing or moving it from the body. This may lead
				to the sensation of a full bowel. Anxiety or nervousness, eating quickly, gulping food or beverages and chewing
				gum can all cause you to swallow increasing amounts of air.
			</li>
		</ul>

		<Par>Less common causes of abdominal bloating:</Par>

		<ul>
			<li className="mt-3">
				<b>Partial bowel obstruction</b> <br />A blockage at some point in the intestine may lead to bloating and this
				diagnosis should be suspected if there is a recent onset of nausea and vomiting.
			</li>
			<li className="mt-3">
				<b>Malabsorption syndromes</b>
				<br /> A number of disorders exist where the body cannot breakdown and absorb certain components of food.
				Lactose intolerance, coeliac disease and other food intolerances are examples of conditions that can cause
				bloating by these mechanisms.
			</li>
			<li className="mt-3">
				<b>Tumours</b> <br /> Very occasionally you may feel bloated because there is a tumour of the ovary, liver,
				stomach or elsewhere within the abdomen.
			</li>
			<li className="mt-3">
				<b>Ascites</b>
				<br />A collection of fluid within the abdominal cavity is called ascites. This is also a result of diseases of
				the liver where it may be associated with malignancy.
			</li>
			<li className="mt-3">
				<b>Parasitic disorders</b>
				<br />
				If the patient has recently returned from overseas then certain parasitic disorders can cause abnormal
				dilatations of the colon (megacolon) and lead to bloating.
			</li>
		</ul>

		<Par>
			A detailed history is important particularly such details as the duration of the bloating, the relationship to
			diet or menstrual cycle. Obviously associated symptoms e.g. nausea, vomiting, constipation and diarrhoea are
			important. A detailed past medical history e.g. history of malignancy or abdominal surgery and also their current
			medications e.g. consumption of strong pain killers.
		</Par>
		<Par>
			If bloating is of recent onset in someone over the age of 50 then underlying pathology is more likely and
			screening tests need to be considered:
		</Par>
		<ul>
			<li className="mt-3"> Blood and stool tests to look for evidence of malabsorption or infection</li>
			<li className="mt-3">Plain X rays of the abdomen to look for obstruction</li>
			<li className="mt-3">Ultrasound of the abdomen & pelvis to look for ascites and solid organ tumours</li>
			<li className="mt-3">
				Endoscopic investigations may be required if there is a suspicion of intestinal obstruction
			</li>
		</ul>
		<h2 className="mt-4">Tips for dealing with bloating</h2>
		<ul>
			<li className="mt-3">Eat slowly and chew food thoroughly</li>
			<li className="mt-3">Avoid carbonated beverages and avoid gulping and sipping liquids</li>
			<li className="mt-3">Avoid chewing gum and sucking on hard lollies</li>
			<li className="mt-3">Eliminate pipes, cigars and cigarette smoking</li>
			<li className="mt-3">Eliminate or minimise sugar and artificial sweetener consumption</li>
			Cut back on vegetables that produce excess gas e.g beans, cabbage, brussel sprouts, cauliflower, broccoli,
			cucumbers and excess raw fruit and vegetables
			<li className="mt-3">Cut back on dairy products</li>
			<li className="mt-3">Eat small meals, i.e. do not overload the stomach in any one meal</li>
			<li className="mt-3">Avoid tight fitting garments</li>
			<li className="mt-3">Do not lie down or sit in a slumped position within 2-3 hours after eating</li>
			<li className="mt-3">Simethicone and activated charcoal (available over the counter) may provide some benefit</li>
			<li className="mt-3">Take a leisurely stroll after meals</li>
			<li className="mt-3">Consider peppermint tea</li>
		</ul>
		<Par>Abdominal bloating associated with recent weight gain</Par>
		<Par>This is a common scenario and unfortunately the only remedy is weight loss!</Par>
	</Blog>
);
